<template>
    <div style="display:flex;justify-content:center;">
        <div :class="['header-title',bgcolor]">
            <div>{{title}}</div>
            <div :class="['icon-type',icon]"></div>
        </div>
    </div>
</template>
<script>
export default {
    name:'HeaderTypeGame',
    props:['title'],
    data:()=>({
        bgcolor:'',
        icon:''
    }),
    mounted(){
        if(this.title == 'Percepción')
        {
            this.bgcolor = 'percepcion'
            this.icon = 'self-awareness-icon'
        }
        else if(this.title == 'Cálculo')
        {
            this.bgcolor = 'calculo'
            this.icon = 'calculadora-icon'
        }
        else if(this.title == 'Razonamiento')
        {
            this.bgcolor = 'razonamiento'
            this.icon = 'psicologia-icon'
        }
        else if(this.title == 'Espacio')
        {
            this.bgcolor = 'espacio'
            this.icon = 'cube-icon'
        }
        else if(this.title == 'Memoria')
        {
            this.bgcolor = 'memoria'
            this.icon = 'memoria-icon'
        }
        else if(this.title == 'Lenguaje')
        {
            this.bgcolor = 'espacio'
            this.icon = 'lenguaje-icon'
        }
    }
}
</script>
<style>
    .icon-type{
        width: 30px;
        height: 30px;
        background-size: 100% 100%;
    }
    .header-title{
        display:flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 5px;
        padding: 10px;
        font-size: 1.2em;
        color:white;
        width: 90%;
    }
    .psicologia-icon{
    background-image: url('../assets/icons/psicologia.svg');
    }
    .calculadora-icon{
        background-image: url('../assets/icons/calculadora.svg');
    }
    .self-awareness-icon{
        background-image: url('../assets/icons/self-awareness.svg');
    }
    .memoria-icon{
        background-image: url('../assets/icons/memoria.svg');
    }
    .cube-icon{
        background-image: url('../assets/icons/3d-cube.svg');
    }
    .lenguaje-icon{
        background-image: url('../assets/icons/lenguaje.svg');
    }
    .percepcion{
    background-color:#E2993B;
    }
    .calculo{
        background-color: #B91000;
    }
    .razonamiento{
        background-color: #580380;
    }
    .espacio{
        background-color: #0468BF;
    }
    .memoria{
        background-color: #055813;
    }
</style>