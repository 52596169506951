<template>
    <div class="games-menu-container">
        <nav-bar sizetitle="sm" :nivel="nivelSelected"></nav-bar>
        <header-type-game :title="tipoJuegoSelected"></header-type-game>
        <div class="gm__container">
            
            <div class="gm__items">
                <div 
                    v-for="item in juegosDelNivelyTipo" 
                    :key="item.id"
                    class="juego-container"
                >
                    <div class="box-game" @click="goGame(item.urlvista)">
                        <div class="title-icon">
                            <div class="box-game__title">
                                {{item.nombre}}
                            </div>
                            <img :src="item.icon" alt="" class="box-game__icon">
                        </div>
                        
                        <div class="box-game__difficulty">
                            Dificultad: {{item.dificultad}}
                        </div>
                    </div>  
                </div>
            </div>
            <div class="gm__instructions-container">
                <div class="gm__text-student-container">
                    <div class="gm__text-student">
                        Escoge uno de los juegos y diviértete
                    </div>
                </div>
                <div class="gm__student-container">
                    <img class="gm__student-cramer" src="../assets/characters/boy-cramer.png" alt="student cramer">
                </div>
            </div>
        </div>
        <div class="gmc__spacer"></div>
    </div>
</template>

<script>
import NavBar from '../components/NavBar.vue'
import HeaderTypeGame from '../components/HeaderTypeGame.vue'
import {mapState,mapMutations} from 'vuex'
export default {
    data:()=>({
        juegosDelNivel:{},
        juegosDelNivelyTipo:[]
    }),
    components:{
        NavBar,
        HeaderTypeGame
    },
    computed:{
        ...mapState(['nivelSelected','tipoJuegoSelected','juegos'])
    },
    methods:{
        ...mapMutations(['changeMenuBarBottom']),
        getJuegos(){
            try{
                if(this.nivelSelected||this.tipoJuegoSelected){
                    this.juegosDelNivel = this.juegos['nivel'+this.nivelSelected]
                    
            
                    let juegos = this.juegosDelNivel.filter((e)=>e.tipo==this.tipoJuegoSelected)
                    
                    this.juegosDelNivelyTipo = juegos
                }
                
            }catch(e){
                console.error(e)
            }
        },
        goGame(url){
            this.$router.push(url)
            this.changeMenuBarBottom(false)
        }
    },
    mounted(){
        if(!this.nivelSelected||!this.tipoJuegoSelected)
        {
            this.$router.replace('/home')
        }
        this.getJuegos()
        this.changeMenuBarBottom(true)
        
    }
}
</script>
<style>
.games-menu-container{
    background-color:#1F0275;
    min-height: 100vh;
}
.juego-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;
    margin-top: 0.5em;
}
.box-game:first-child{
    margin-top: 1em;
}
.title-icon{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.box-game{
    background-color: #EEEFE4;
    width: 95%;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.5s;
}
.box-game:hover{
    box-shadow: 0px 0px 20px white;
}
.box-game__title{
    width: 50%;
    font-size: 2.2em;
    text-align: center;
}
.box-game__icon{
    width: 100px;
}
.box-game__difficulty{
    margin-top: 1em;
    margin-left: 1em;
}
.gmc__spacer{
    display: block;
    height: 6em;
}
.gm__text-student-container{
    display: none;
}
.gm__student-cramer{
    display: none;
}
@media (min-width: 900px) and (orientation: landscape) {
    .games-menu-container{
        background-image: url('../assets/bg-desktop/bg-home.svg');
        background-position-x: -20px;
        background-position-y: -200px;
        background-size: 100%;
    }
    .gm__student-cramer{
        display: block;
    }
    .gm__container{
        margin-top: 2em;
        display: grid;
        grid-template-columns: 30% 30% 30%;
        grid-template-rows: 100%;
        align-content: center;
        justify-items: center;
        align-self: center;
        justify-content: center;
    }
    .gm__items{
        grid-column: 1;
    }
    .gm__instructions-container{
        width: 100%;
        height: 100%;
        grid-column: 3;
        position: relative;
    }
    .gm__student-container{   
        width: 80%;
    }
    .gm__student-cramer{
        width: 100%;
        height: 100%;
    }
    .gm__text-student-container{
        display: block;
        background-color: white;
        border-radius: 20px;
        width: 80%;
        position: absolute;
        left: -50%;
    }
    .gm__text-student{
        font-size: 2em;
        padding: 0.5em 1em;
        
    }
    .gmc__spacer{
        display: none;
    }
}
</style>